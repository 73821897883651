@import "./ComponentsWeb/Sub/color.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.f-14{
  font-size: 14px;
}
.bg-thememain {
  background: var(--themeMain);
}

.bg-themesecond {
  background-color: var(--secondColor);
}

.login-btn:hover {
  background-color: var(--secondColor) !important;
}

body {
  font-family: "Rubik", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
}

button {
  border: none !important;
}

button:hover {
  background-color: var(--secondColor) !important;
  color: var(--secondText) !important;
}

li {
  list-style: none;
}

td {
  font-size: 15px;
}

.side-menu {
  position: fixed;
  background-color: var(--themeMain);
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  transition: width 0.2s ease-in;
  overflow: auto;
}

/* .side-menu .top-section .nav {
  color: var(--firstText) !important;
} */

.side-menu.inactive {
  width: 83px;
}

.top-section {
  position: relative;
}

.side-menu .logo {
  width: 140px;
  height: auto;
  overflow: hidden;
  display: inline-block;
}

.side-menu .logo img {
  max-width: 100%;
  max-height: 100%;
}

.toggle-menu-btn {
  font-size: 28px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  cursor: pointer;
}

.side-menu.inactive .toggle-menu-btn {
  right: -60px;
}

.menu-item {
  font-size: 15px;
  display: block;
}

.table-scrolling {
  overflow-x: auto;
  display: block;
  width: 100%;
  /* height: 79vh; */
}

.font-weight-bold {
  font-weight: bold;
}

.product-name-box {
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .m-h-50 {
    height: 45vh;
  }
  .reverse{
    flex-direction: row-reverse;
  }
}

@media only screen and (min-width: 600px) {
  .height-100vh {
    height: 100vh;
    overflow: hidden;
  }
  .reward-offer {
    height: 3vh;
  }

  .desktop-hide {
    display: none;
  }

  .all-calculated-amount-tbl li {
    font-size: 18px;
    font-weight: 500;
  }

  .logoForm {
    width: 30% !important;
  }
}

.menu-icon {
  display: inline-block;
}

.width-auto {
  width: auto;
}

.f-right {
  float: right;
}

.invalid-feedback {
  text-transform: capitalize;
}

.first-latter-capital {
  text-transform: capitalize;
}

.content {
  background: #F8FAFB;
  width: calc(100% - 200px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.content-area {
  min-height: 100vh;
  transition: all 0.3s;
  top: 10%;
  right: 0;
}

.content-active {
  width: 94%;
  position: absolute;
  left: 6%;
  overflow: hidden;
}

.dropdown-menu[data-bs-popper] {
  left: -80%;
}

.mt-extra {
  margin-top: 4.25rem !important;
}

.card-width {
  width: 21rem;
}

.margin-left {
  margin-left: 25%;
}

.margin-m-left {
  margin-left: 5%;
}

/* //login form Css */
a {
  text-decoration: none;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}

.align-item-center {
  align-items: center;
}

.form-right i {
  font-size: 100px;
}

.nav-link {
  font-size: 16px;
  padding: 0px;
}

hr {
  margin: 0.5rem 0;
}

.dashboard-bottom {
  height: 1px;
  width: 100px;
  background-color: var(--themeMain);
}

.bg-side {
  background-color: var(--themeMain);
}

.display-n {
  display: none;
}

.hide-side-menu {
  display: none;
}

.bg-thememain {
  background: var(--themeMain);
}

.sideMenuLinks {
  padding: 6px 5px;
  color: var(--firstText);
}

/* .nav-link{
color: #fff;
} */
.selectedBox {
  background-color: var(--success);
  color: var(--firstText);
}

.sub-tbl tr,
th,
td {
  border-style: solid;
  border-width: 1px;
  padding: 0px 2px;
}

.over-col-size {
  width: 14%;
}

.table>:not(caption)>*>* {
  padding: 0.2rem 0.2rem !important;
}

.my-profile-content p {
  font-size: 20px;
}

.my-profile-content {
  box-shadow: 1px 2px 5px 0px;
}

.nav-item {
  color: var(--firstText) !important;
}


.nav-link:focus,
.nav-link:hover {
  color: var(--secondColor);
}

.side-space {
  margin-left: 20px;
}

.side-menu .active {
  color: var(--secondColor) !important;
  background-color: var(--sidecolor);
}

.demo a .active {
  color: var(--secondColor) !important;
  background-color: var(--sidecolor);
}


@media screen and (max-width: 1100px) {
  table {
    overflow-x: auto;
    overflow-y: auto;
    display: block;
  }

}

table p {
  font-size: 14px !important;
}

/* //responsive design */
@media (min-width: 320px) and (max-width: 480px) {
  .productsreview .nav-link {
    padding: 6px !important;
    font-size: 14px !important;
    margin-right: 0px !important;
  }

  .w-70 {
    width: 70%;
  }

  .p-mobile {
    margin-top: 10px;
    margin-left: -50px;
  }

  .mbt-phone {
    margin-left: -30px;
    margin-top: 10px;
  }

  .mb-phone {
    margin-bottom: 10px;
  }

  .side-menu {
    width: 33%;
  }

  .side-menu .logo {
    width: 50px;
  }

  .content {
    width: calc(67%);
  }

  .ds {
    display: none;
  }

  .nav-link {
    font-size: 15px;
    padding: 0px;
  }

  .nav.fs-3.mt-1.flex-column {
    margin-left: -8px;
  }

  .mbl-none {
    display: none;
  }
}

/* //responsive design */
.notFountPage {
  text-align: center;
  width: 50%;
  margin: auto;
  margin-top: 30vh;
  background: #ccc;
  padding: 50px 25px;
}

.tbl-img {
  height: 50px;
  border-radius: 50%;
  width: auto !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.auto-scrolling-tbl {
  overflow: auto;
  height: 70vh;
}

.float-right {
  float: right;
}

.shipping-cost-tab {
  background: var(--secondColor);
  text-align: center;
}

.image img {
  width: auto !important;
}

.blink_img {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.fixed-whatsapp {
  position: fixed;
  bottom: 0;
  right: 6px;
  background: #25ca25;
  color: #fff;
  padding: 4px 10px;
  font-size: 25px;
  border-radius: 50%;
}

.singIn {
  background: #ff832b;
  padding: 2px 5px;
  border-radius: 8px;
  color: #0b2b42;
  font-weight: 600;
}

.small-font {
  font-size: 15px;
}

.w-40 {
  width: 40% !important;
}

.align-item-center {
  align-items: center;
}

.f-18 {
  font-size: 18px;
}

.w-75 {
  width: 75%;
}

.user-cart-item {
  background-color: #ccc;
}

.offer-desc p {
  font-size: 18px;
}

.list-style-dot li {
  list-style-type: disc;
}

.reward-offer {
  color: #cc0c39;
}

.reward-offer span {
  background-color: #cc0c39;
  color: #fff;
  padding: 4px;
}
.reward-offer-single{
  text-align: center;
  background-color: #cc0c39;
  color: #fff;
  padding: 7px 4px;
}